import shopifySparkGenericDefaults from '../shopify-spark-generic/config.js';

export default {
  includes: ['shopify-spark-generic'],
  ...shopifySparkGenericDefaults,
  fitmentSearch: {
    ...shopifySparkGenericDefaults.fitmentSearch,
    forceVehicleSelection: true,
  },
};
